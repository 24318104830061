<template>
  <div class="modal playerModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <!-- Modal's Header -->
        <header class="modal-header playerProfile">
          <!-- Player's image -->
          <img
            :src="`https://cdn.sportsforecaster.com/players/l.nhl.com/${this.player.player_key}/head/no-background/web`"
          />

          <h5 class="modal-title">
            <!-- Player's name -->
            <span>
              {{ this.player.name }}
            </span>
            <br />
            <!-- Goals so far -->
            <span style="font-size: 1rem">
              {{ goalsScored }} goals in {{ actualGamesPlayed }} games to date ({{ goalsPerGame }} G/GP)
            </span>
          </h5>
          <button type="button" class="fs-3 ms-auto btn align-self-start" @click="close()"><i class="text-white bi bi-x"></i></button>
        </header>

        <div class="modal-body">
          <!-- Predicted goals -->
          <div class="row">
            <!-- Factors -->
            <div class="col-12 col-md-6">
              <h4 class="mb-3 d-flex align-items-center justify-content-around">
                Sports Forecaster Four Factors
                <img style="max-width: 40px" :src="require('../assets/4f-ball.png')" />
              </h4>
              <div class="factors-section" style="position: relative">
                <!-- Opposing Defense a.k.a Goals Against Average -->
                <div class="factor opossingDefense" @click="toggleInfoKey('opossing')">
                  <img :src="require('../assets/f1-defense.png')" /><br />
                  <span class="score">
                    <!-- {{ getFactorValue("algorithms.goals-against-average.scaling_factor") }}% -->
                    {{ getFactorValue("algorithms[3].scaling_factor") }}%
                  </span>
                </div>

                <!-- Site Advantage a.k.a Home-Away Weighting -->
                <div class="factor siteAdvantage" @click="toggleInfoKey('site')">
                  <img :src="require('../assets/f2-site.png')" /><br />
                  <span class="score">
                    <!-- {{ getFactorValue("algorithms.home-away-weighting.scaling_factor") }}% -->
                    {{ getFactorValue("algorithms[2].scaling_factor") }}%
                  </span>
                </div>

                <!-- Schedule and Fatigue -->
                <div class="factor scheduleFatigue" @click="toggleInfoKey('schedule')">
                  <img :src="require('../assets/f3-schedule.png')" /><br />
                  <span class="score">
                    <!-- {{ getFactorValue("algorithms.schedule.scaling_factor") }}% -->
                    {{ getFactorValue("algorithms[5].scaling_factor") }}%
                  </span>
                </div>

                <!-- X-Factor -->
                <div class="factor xFactor" @click="toggleInfoKey('x_factor')">
                  <img :src="require('../assets/f4-x.png')" /><br />
                  <span class="score">
                    <!-- {{ getFactorValue("algorithms.x-factor.scaling_factor") }}% -->
                    {{ getFactorValue("algorithms[4].scaling_factor") }}%
                  </span>
                </div>
              </div>
              <!-- Total boost -->
              <div class="total-boost">
                <div class="d-flex align-items-center">
                  <div class="line border-top"></div>
                  <div class="box mx-3">
                    <span>{{ getTotalBoost }}</span>
                  </div>
                  <div class="line border-top"></div>
                </div>
              </div>
              <div class="has-text-left py-3" v-html="showInformation"></div>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center">
              <p>
                Forecast of {{ predictedGoalsOfSeason }} goals for the season
                <br />
                {{ predictedGoalsOfSeason-goalsScored }} goals in {{ gamesLeft }} games remaining ({{predictedGoals}} G/GP)
              </p>
              <div class="col-12">
                <div class="border border-dark">
                  <div class="teams-date">
                    <img
                      :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${this.awayTeam.team_key}.png`"
                    />
                    @
                    <img
                      :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${this.homeTeam.team_key}.png`"
                    />
                  </div>
                  <p>
                    <span class="upper"> {{ this.player.name }} forecast:</span>
                    {{ Number(this.player.algorithms[0].guessing_score).toFixed(2) }} GOALS
                  </p>
                </div>
                <!-- <div class="mt-4"> -->
                  <!-- TODO Add a small icon of the factor.  -->
                  <!-- <img v-if="infoKey" :src="getFactorImage()" alt="Icon" /> -->
                  <!-- <div class="has-text-left py-3 border-top" v-html="showInformation"></div> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>

        <footer class="modal-footer">
          <slot name="footer"> </slot>
          <button
            type="button"
            class="spofo_pro_button"
            style="max-width: 150px; margin: 0px 0px 0px; padding: 8px; font-size: 15px"
            @click="close()"
          >
            <i class="bi bi-x-circle" style="font-size: 1.2em"></i> <span>CLOSE</span>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "PlayerModal",
  props: ["player", "homeTeam", "awayTeam"],

  data: () => ({
    infoKey: null,
  }),

  computed: {
    getTotalBoost() {
      let value = _.get(this.player, "algorithms[0].boost");
      let sign = this.getSign(value);
      return `${sign}${value.toFixed(1)}%`;
    },

    goalsScored() {
      // return _.get(this.player, "algorithms.average.intermediate_values.goals-scored");
      return _.get(this.player, "algorithms[1].intermediate_values.goals_scored");
    },

    actualGamesPlayed() {
      return _.get(this.player,"extra_data.actual_games_played");
    },

    goalsPerGame() {
      let result = this.goalsScored / this.actualGamesPlayed;
      return result.toFixed(2);
    },

    predictedGoalsOfSeason() {
      // return _.get(this.player,"algorithms.average.intermediate_values.predicted-goals-season");
      return _.get(this.player,"algorithms[1].intermediate_values.predicted_goals");
    },

    predictedGoals() {
      var result = (this.predictedGoalsOfSeason - this.goalsScored) / this.gamesLeft;
      return result.toFixed(2);
    },

    gamesLeft() {
      // return _.get(this.player, "algorithms.average.intermediate_values.games-left");
      return _.get(this.player, "algorithms[1].intermediate_values.games_left");
    },

    isGameHouse() {
      // return (_.get(this.player,"algorithms.home-away-weighting.intermediate_values.game-house") === "home");
      return (_.get(this.player,"algorithms[2].intermediate_values.game_house") === "home");
    },

    showInformation() {
      let result = "";
      switch (this.infoKey) {
        case "opossing": {
          var awayString = `<strong>Opposing Defense:</strong> ${this.awayTeam["name"]}'s goals-against average is at the ${this.player.algorithms[3].intermediate_values.opponent_gaa_rank}th place in the NHL.`;
          var homeString = `<strong>Opposing Defense:</strong> ${this.homeTeam["name"]}'s goals-against average is at the ${this.player.algorithms[3].intermediate_values.home_gaa_rank}th place in the NHL.`;

          result = this.isGameHouse ? awayString : homeString;
          break;
        }

        case "site": {
          // let boost = parseInt(_.get(this.player, "algorithms.home-away-weighting.boost"));
          let boost = parseInt(_.get(this.player, "algorithms[2].boost"));
          console.log(boost)

          let generatedGoals = ''

          if(boost>0){
            if(boost>=10){
              generatedGoals = `far more goals`
            }else if(boost >= 3 && boost < 10){
              generatedGoals = `more goals`
            }else{
              generatedGoals = `around the same goals ${this.isGameHouse ? 'as when playing on the road' : 'as when playing at home'}`
            }
          }else{
            if(boost <= -10){
              generatedGoals = `far fewer goals`
            }else if(boost <= -3 && boost > -10){
              generatedGoals = `fewer goals`
            }else{
              generatedGoals = `around the same goals ${this.isGameHouse ? 'as when playing on the road' : 'as when playing at home'}`
            }
          }
          // result = `<strong>Site Advantage</strong>: When playing at home, ${this.player["player-name"]} generates far fewer goals.`
          if (this.isGameHouse) {
            result = `<strong>Site Advantage</strong>: When playing at home, ${this.player.name} generates ${generatedGoals}.`
          } else {
            result = `<strong>Site Advantage</strong>: When playing on the road, ${this.player.name} generates ${generatedGoals}.`;
          }
          break;
        }

        case "schedule": {
          // let boost = parseInt(_.get(this.player, "algorithms.schedule.boost"));
          let boost = parseInt(_.get(this.player, "algorithms[5].boost"));

          if (this.isGameHouse) {
            if (boost === 0) {
              result = `<strong>Schedule and Fatigue</strong>: No schedule factor this game.`;
            } else if (boost === 7) {
              result = `<strong>Schedule and Fatigue</strong>: ${this.homeTeam.name} is
                  rested team playing tired team.`;
            } else if (boost === -10) {
              result = `<strong>Schedule and Fatigue</strong>: ${this.homeTeam.name} is
                  tired team playing rested team.`;
            } else if (boost === -12) {
              result = `<strong>Schedule and Fatigue</strong>: ${this.homeTeam.name} is
                  deep into a bigroad trip.`;
            }
          } else {
            if (boost === 0) {
              result = `<strong>Schedule and Fatigue</strong>: No schedule factor this game.`;
            } else if (boost === 7) {
              result = `<strong>Schedule and Fatigue</strong>: ${this.awayTeam.name} is
                  rested team playing tired team.`;
            } else if (boost === -10) {
              result = `<strong>Schedule and Fatigue</strong>: ${this.awayTeam.name} is
                  tired team playing rested team.`;
            } else if (boost === -12) {
              result = `<strong>Schedule and Fatigue</strong>: ${this.awayTeam.name} is
                  deep into a bigroad trip.`;
            }
          }
          break;
        }

        case "x_factor": {
          // var boost = parseInt(_.get(this.player, "algorithms.x-factor.boost"));
          result = `<strong>X-Factor</strong>: No X-Factors for this game.`;
          break;
        }

        default:
          result = "Click icons for factor details.";
          break;
      }

      return result;
    },
  },

  methods: {
    toggleInfoKey(key) {
      this.infoKey = this.infoKey === key ? null : key;
    },

    getFactorImage() {
      var mappings = {
        opossing: "../assets/f1-defense.png",
        site: "../assets/f2-site.png",
        schedule: "../assets/f3-schedule.png",
        x_factor: "../assets/f4-x.png",
      };

      return require(mappings[this.infoKey]);
    },

    getFactorValue(key) {
      let result = 0;
      let factor = _.get(this.player, key);

      if (factor) {
        result = ((factor - 1) * 100).toFixed(1);
      }

      let sign = this.getSign(result);
      return `${sign}${result}`;
    },

    getSign(number) {
      let result = Math.sign(number);
      let mappings = {
        1: "+",
        "-1": "",
        0: "",
      };

      return mappings[result];
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="css" scoped>
.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-body {
  /* padding: 1em 2em 0; */
  padding: 2em;
}

.box {
  background-color: black;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.box span {
  color: white;
  font-size: 1.5rem;
}

.has-text-left {
  text-align: left;
}

.factor{
  flex: 1;
  text-align: center;
}
.factor img{
  width: 75px;
  height: 75px;
  margin-bottom: 1rem;
}
.total-boost .line{
  flex: 1;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
  .modal-header{
    justify-content: flex-start;
  }
  .modal-body{
    /* display: flex; */
    /* align-items: center; */
  }
  .modal-body>div{
    flex: 0 50%
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    width: 800px;
    max-width: 800px;
  }
}
</style>
